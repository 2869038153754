import { useState, useEffect, useCallback } from "react";
import { Icon } from "@storyofams/react-ui";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import styled from "styled-components";

import { SignupPopup } from "~components/common/Popups/SignupPopup";
import { Button } from "../../../components";
import { Heart, HeartOutline } from "../../../components/common/Icons";
import { useShopify, useWishlist } from "../../../context";

const spring = {
  type: "spring",
  damping: 10,
  stiffness: 100,
};

const Wrapper = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddToWishlistButton = ({
  product,
  isBundle = false,
  ...props
}) => {
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { customer } = useShopify();
  const { wishlist, addOrRemoveFromWishlist } = useWishlist();
  const posthog = usePostHog();
  const router = useRouter();
  useEffect(() => {
    if (
      wishlist?.some(({ handle }) => handle === product?.handle) &&
      !isInWishlist
    ) {
      setIsInWishlist(true);
    }
  }, [wishlist]);
  const updateWishlist = (action) => {
    setIsInWishlist(action);
    addOrRemoveFromWishlist(action ? "add" : "remove", {
      productId: product?.id,
      variantId:
        product?.variants?.edges[0]?.node?.id ||
        Buffer.from(
          `gid://shopify/ProductVariant/${product.objectID}`
        ).toString("base64"),
      product,
    });
  };
  const close = useCallback(() => setIsOpen(false), []);
  const signupPopupHandle = () => {
    const decodedCookies = decodeURIComponent(document.cookie).split(";");
    const { signupPopup: isReturning } = Object.assign(
      {},
      ...decodedCookies.map((cookie) => {
        const [key, value] = cookie.split("=");
        return { [key.trim()]: value };
      })
    );
    if (isReturning) {
      return;
    }
    setIsOpen(true);
    document.cookie = "signupPopup=1";
  };
  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          // posthog.capture("Click add to wishlist", {
          //   product: product.title,
          //   source: props?.isPP ? "Product page" : "Prodcut Card",
          //   page: router.pathname,
          //   slug: router.query?.slug || router.query?.handle || null,
          // });
          if (!customer) signupPopupHandle();
          updateWishlist(!isInWishlist);
        }}
        variant="unstyled"
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={isBundle ? 1 : 2.5}
        size={isBundle ? 20 : 56}
        bg="white"
        border={!isBundle && "1px"}
        borderColor={isInWishlist ? "oldPink" : "grey200"}
        borderRadius="md"
        ariaLabel={isInWishlist ? "Remove from wishlist" : "Add to wishlist"}
        {...props}
      >
        <AnimatePresence>
          {isInWishlist && (
            <Wrapper
              initial={{ opacity: 0, transform: "scale(0)" }}
              exit={{ opacity: 0, transform: "scale(0)" }}
              animate={{ opacity: 1, transform: "scale(1)" }}
              transition={spring}
            >
              <Icon
                icon={Heart}
                fontSize={isBundle ? 2 : 2.5}
                color="oldPink"
              />
            </Wrapper>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {!isInWishlist && (
            <Wrapper
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: "easeInOut", duration: 0.24 }}
            >
              <Icon
                icon={HeartOutline}
                fontSize={isBundle ? 2 : 2.5}
                color="black"
              />
            </Wrapper>
          )}
        </AnimatePresence>
      </Button>
      <SignupPopup close={close} isOpen={isOpen} />
    </>
  );
};
